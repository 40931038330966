export const CORREO = {
    desa: 'http://localhost:8080',
    prod: 'https://appifigarantias.com',
    api: 'https://figatech.com', 
    correo_interno: '/servidor_correo/indexinterno.php',
    correo: '/servidor_correo/index.php',
    apicert: '/apireport-saldos-consolidados/webresources/generic/',
    apinormal: '/apireport-saldos-consolidados/webresources/genericnormal/',
    apinormalgeneric: '/apireport-saldos-consolidados/webresources/corriente/generic',
    apipageneric: '/apireport-saldos-consolidados/webresources/pa/generic',
    apidoble: '/apireport-saldos-consolidados/webresources/genericdoble/',
    apiopera: '/apireport-saldos-consolidados/webresources/genericopera'
     
}
