export const environment = {
  production: true, 
  firebaseConfig : {
    apiKey: 'AIzaSyDNA6HWStBOHMbSqjl4QolJqf3BdNG61CY',
    authDomain: 'softwarefigarantiasprod-01.firebaseapp.com',
    databaseURL: 'https://softwarefigarantiasprod-01.firebaseio.com',
    projectId: 'softwarefigarantiasprod-01',
    storageBucket: 'softwarefigarantiasprod-01.appspot.com',
    messagingSenderId: '574901478795',
    appId: '1:574901478795:web:ea54906ab8922c57e3bb8e',
    measurementId: 'G-75XQL0D90P'
  },
  firebaseConfigPaginaWeb : {
    apiKey: 'AIzaSyCRs5rpa1msPVM38H_MIL_bcKKKvd0IRbs',
    authDomain: 'paginawebfigarantiasprod-01.firebaseapp.com',
    projectId: 'paginawebfigarantiasprod-01',
    storageBucket: 'paginawebfigarantiasprod-01.appspot.com',
    messagingSenderId: '2159660234',
    appId: '1:2159660234:web:0348773e6eb4e7e6ebb833',
    measurementId: 'G-Q0JRBFLJRT'
  }
};
