import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../model/contact.model';
import { Correo } from '../model/correo.model';
import { Count } from '../model/count.model';
import { GarantiasTotal } from '../model/garantiastotal.model';
import { Intermediario } from '../model/intermediario.model';
import { CORREO } from './urlServices'; 
import { ReporteOperacion } from '../model/reporteoperacion.model';
import { HttpHeaders } from '@angular/common/http'; 
import * as $ from 'jquery'; 
import { CheckNormal } from '../model/checknormal.model';
import { CheckDividida } from '../model/checkdividida.model';
import { Bancos } from '../model/bancos.model';
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "text/plain",
    'Access-Control-Allow-Origin': '*',
  })
};
@Injectable({
    providedIn: 'root'
  })
export class ServidorCorreoService {

    constructor(private Http: HttpClient) { }
 
    public getCorreo(corre: Contact): Observable<Contact> {   
        const headers = { 'content-type': 'application/json'}  
        return this.Http.get<Correo>(CORREO.api + CORREO.correo + "?correo=" + corre.ema+"&asunto="+corre.ent+"&mensaje="+corre.des,{'headers':headers} );
    }
  
    public getCert(intermediario: Intermediario) {   
        var date: Date = new Date();
        var mes = this.castMes(date);  
        return this.Http.get(CORREO.prod + CORREO.apicert + intermediario.descripcion + '/'+intermediario.sigla + '/'+intermediario.nit + '/'+date.getDate().toString()  + '/'+mes+ '/'+date.getFullYear().toString()+ '/'+intermediario.coberturacreditomora)
    }

    public getCertNormal(intermediario: Intermediario, garantiasTotal : GarantiasTotal, count:Count) {   
        var date: Date = new Date(); 
        var mes = this.castMes(date); 
        var mesGarantia = this.castMesGarantia(garantiasTotal);  
        return this.Http.get(CORREO.prod + CORREO.apinormal + count.index+'/'+ intermediario.descripcion + '/'+intermediario.sigla + '/'+intermediario.nit + '/'+date.getDate().toString()  + '/'+mes+ '/'+date.getFullYear().toString()+ '/'+garantiasTotal.comisiontotal+ '/'+garantiasTotal.cobertura+ '/'+garantiasTotal.administracion+ '/'+garantiasTotal.ivaadministracion+ '/'+mesGarantia+ '/'+garantiasTotal.fechareporte.toString().substring(0,4))
    }

    public getCertDoble(intermediario: Intermediario, garantiasTotal : GarantiasTotal, count:Count) {   
        var date: Date = new Date();
        var mes = this.castMes(date); 
        var mesGarantia = this.castMesGarantia(garantiasTotal); 
        var admiva = parseInt(garantiasTotal.comisiontotal) - parseInt(garantiasTotal.cobertura); 
        return this.Http.get(CORREO.prod + CORREO.apidoble +count.index+'/'+ garantiasTotal.idgarantia+'/'+ intermediario.descripcion + '/'+intermediario.sigla + '/'+intermediario.nit + '/'+date.getDate().toString()  + '/'+mes+ '/'+date.getFullYear().toString()+ '/'+garantiasTotal.comisiontotal+ '/'+garantiasTotal.cobertura+ '/'+garantiasTotal.administracion+ '/'+garantiasTotal.ivaadministracion+ '/'+mesGarantia+ '/'+garantiasTotal.fechareporte.toString().substring(0,4)+'/'+admiva)
    }

    public getCertNormalGeneric(intermediario: Intermediario, garantiasTotal : GarantiasTotal, count:Count, checknormal:CheckNormal){
        var date: Date = new Date(); 
        var mes = this.castMes(date); 
        var mesGarantia = this.castMesGarantia(garantiasTotal);  
        var jsonopt = "{\r\n    \"idgarantia\": \"" + count.index + "\",\r\n    \"descripcion\": \"" + intermediario.descripcion
        + "\",\r\n    \"sigla\": \"" + intermediario.sigla  + "\",\r\n    \"nit\": \"" + intermediario.nit
        + "\",\r\n    \"dia\": \"" + date.getDate().toString() + "\",\r\n    \"mes\": \"" + mes
        + "\",\r\n    \"nitEmpresa\": \"" + checknormal.nitEmpresa + "\",\r\n    \"nombreEmpresa\": \"" + checknormal.nombreEmpresa
        + "\",\r\n    \"ano\": \"" + date.getFullYear().toString() + "\",\r\n    \"saldo\": \"" + garantiasTotal.comisiontotal
        + "\",\r\n    \"cobertura\": \"" + garantiasTotal.cobertura + "\",\r\n    \"administracion\": \"" + garantiasTotal.administracion
        + "\",\r\n    \"ivaadministracion\": \"" + garantiasTotal.ivaadministracion + "\",\r\n    \"fechareporte\": \"" + mesGarantia
        + "\",\r\n    \"anioreporte\": \"" + garantiasTotal.fechareporte.toString().substring(0,4) + "\",\r\n    \"logo\": \"" + checknormal.urllogo
        + "\",\r\n    \"concepto\": \"" + checknormal.concepto + "\",\r\n    \"concepto2\": \"" + checknormal.concepto2
        + "\",\r\n    \"nota\": \"" + checknormal.nota + "\",\r\n    \"firmacontador\": \"" + checknormal.urlfirma + "\",\r\n    \"nombrecontador\": \"" + checknormal.nombrecontador
        + "\",\r\n    \"banco\": \"" + intermediario.banco.banco + "\",\r\n    \"nrocuenta\": \"" + intermediario.banco.nrocuenta + "\",\r\n    \"tipocuenta\": \"" + intermediario.banco.tipocuenta
        + "\",\r\n    \"cargocontador\": \"" + checknormal.cargocontador + "\",\r\n    \"concepto3\": \"" + checknormal.concepto3 +"\"\r\n}";
        return this.Http.post(CORREO.prod + CORREO.apinormalgeneric , jsonopt);
    }

    public getCertDivididaGeneric(intermediario: Intermediario, garantiasTotal : GarantiasTotal, count:Count, checknormal:CheckDividida, bancoC: Bancos, bancoA: Bancos){
        var date: Date = new Date(); 
        var mes = this.castMes(date); 
        var mesGarantia = this.castMesGarantia(garantiasTotal);  
        var jsonopt = "{\r\n    \"idgarantia\": \"" + count.index + "\",\r\n    \"descripcion\": \"" + intermediario.descripcion
        + "\",\r\n    \"sigla\": \"" + intermediario.sigla  + "\",\r\n    \"nit\": \"" + intermediario.nit
        + "\",\r\n    \"bancoc\": \"" + bancoC.banco  + "\",\r\n    \"nrocuentac\": \"" + bancoC.nrocuenta + "\",\r\n    \"tipocuentac\": \"" + bancoC.tipocuenta
        + "\",\r\n    \"dia\": \"" + date.getDate().toString() + "\",\r\n    \"mes\": \"" + mes
        + "\",\r\n    \"nitEmpresa\": \"" + checknormal.nitEmpresa + "\",\r\n    \"nombreEmpresa\": \"" + checknormal.nombreEmpresa
        + "\",\r\n    \"ano\": \"" + date.getFullYear().toString() + "\",\r\n    \"saldo\": \"" + garantiasTotal.comisiontotal
        + "\",\r\n    \"cobertura\": \"" + garantiasTotal.cobertura + "\",\r\n    \"administracion\": \"" + garantiasTotal.administracion
        + "\",\r\n    \"ivaadministracion\": \"" + garantiasTotal.ivaadministracion + "\",\r\n    \"fechareporte\": \"" + mesGarantia
        + "\",\r\n    \"anioreporte\": \"" + garantiasTotal.fechareporte.toString().substring(0,4) + "\",\r\n    \"logo\": \"" + checknormal.urllogo
        + "\",\r\n    \"concepto\": \"" + checknormal.concepto + "\",\r\n    \"concepto2\": \"" + checknormal.concepto2
        + "\",\r\n    \"nota\": \"" + checknormal.nota + "\",\r\n    \"firmacontador\": \"" + checknormal.urlfirma + "\",\r\n    \"nombrecontador\": \"" + checknormal.nombrecontador
        + "\",\r\n    \"banco\": \"" + bancoA.banco + "\",\r\n    \"nrocuenta\": \"" + bancoA.nrocuenta + "\",\r\n    \"tipocuenta\": \"" + bancoA.tipocuenta
        + "\",\r\n    \"cargocontador\": \"" + checknormal.cargocontador + "\",\r\n    \"concepto3\": \"" + checknormal.concepto3 +"\"\r\n}";
        return this.Http.post(CORREO.prod + CORREO.apipageneric , jsonopt);
    }

    castMes(date){
        var mes = "";
        if((date.getMonth() + 1).toString() == "1"){
            mes = "enero";
        }if((date.getMonth() + 1).toString() == "2"){
            mes = "febrero";
        }if((date.getMonth() + 1).toString() == "3"){
            mes = "marzo";
        }if((date.getMonth() + 1).toString() == "4"){
            mes = "abril";
        }if((date.getMonth() + 1).toString() == "5"){
            mes = "mayo";
        }if((date.getMonth() + 1).toString() == "6"){
            mes = "junio";
        }if((date.getMonth() + 1).toString() == "7"){
            mes = "julio";
        }if((date.getMonth() + 1).toString() == "8"){
            mes = "agosto";
        } if((date.getMonth() + 1).toString() == "9"){
            mes = "septiembre";
        }if((date.getMonth() + 1).toString() == "10"){
            mes = "octubre";
        } if((date.getMonth() + 1).toString() == "11"){
            mes = "noviembre";
        }if((date.getMonth() + 1).toString() == "12"){
            mes = "diciembre";
        } 
        return mes;
    }

    castMesGarantia(garantiasTotal){
        var mesGarantia = ""; 
        if(garantiasTotal.fechareporte.toString().substring(5,7) == "01"){
            mesGarantia = "enero";
        }if(garantiasTotal.fechareporte.toString().substring(5,7)== "02"){
            mesGarantia = "febrero";
        }if(garantiasTotal.fechareporte.toString().substring(5,7) == "03"){
            mesGarantia = "marzo";
        }if(garantiasTotal.fechareporte.toString().substring(5,7) == "04"){
            mesGarantia = "abril";
        }if(garantiasTotal.fechareporte.toString().substring(5,7) == "05"){
            mesGarantia = "mayo";
        }if(garantiasTotal.fechareporte.toString().substring(5,7)  == "06"){
            mesGarantia = "junio";
        }if(garantiasTotal.fechareporte.toString().substring(5,7)  == "07"){
            mesGarantia = "julio";
        }if(garantiasTotal.fechareporte.toString().substring(5,7) == "08"){
            mesGarantia = "agosto";
        } if(garantiasTotal.fechareporte.toString().substring(5,7)== "09"){
            mesGarantia = "septiembre";
        }if(garantiasTotal.fechareporte.toString().substring(5,7) == "10"){
            mesGarantia = "octubre";
        } if(garantiasTotal.fechareporte.toString().substring(5,7)== "11"){
            mesGarantia = "noviembre";
        }if(garantiasTotal.fechareporte.toString().substring(5,7)== "12"){
            mesGarantia = "diciembre";
        } 
        return mesGarantia;
    }
}